import Link from "../link/Link";
import React from "react";

import GameonLogoColor from "../../../static/svg/gameon-logo-color.svg";

import "./footer-slack.scss";

const CURRENT_YEAR = new Date().getFullYear();

const FooterSlack = () => (
  <footer className="footer--slack">
    <section className="footer--slack__directory">
      <Link to="/" className="footer--slack__logo-link">
        <GameonLogoColor />
      </Link>
      <div className="footer--slack__directory__column">
        <h4 className="footer--slack__directory__column__heading heading-5">
          GameOn Technology
        </h4>
        <ul>
          <li className="footer--slack__directory__address address body-text">
            555 Clay Street
            <br />
            San Francisco, CA 94111
          </li>
        </ul>
      </div>
    </section>
    <section className="footer--slack__legal">
      <div className="footer--slack__legal__info small-text">
        <div className="footer--slack__legal__info__copyright">
          <div className="footer--slack__legal__info__copyright__text">
            ©{CURRENT_YEAR} GameOn Technology, Inc.&nbsp;
          </div>
          <div className="footer--slack__legal__info__copyright__text">
            All Rights Reserved
          </div>
        </div>
        <Link to="/terms-of-use" className="footer--slack__legal__info__link">
          Terms
        </Link>
        <Link to="/privacy-policy" className="footer--slack__legal__info__link">
          Privacy
        </Link>
      </div>
    </section>
  </footer>
);

FooterSlack.propTypes = {};

FooterSlack.defaultProps = {};

export default FooterSlack;
