import Link from "../link/Link";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import GameonLogoWhite from "../../../static/svg/gameon-logo-white.svg";

import "./hero-chat-stationary.scss";

// Gatsby blur-up for in-repo file
const HeroChatStationary = ({ title, imageData }) => {
  return (
    <div className="hero-chat-stationary">
      <Link to="/" className="gameon-home-link">
        <GameonLogoWhite />
      </Link>
      <h1 className="hero-chat-stationary__title">{title}</h1>
      <GatsbyImage
        alt=""
        className="hero-chat-stationary__image"
        image={imageData}
      />
    </div>
  );
};

HeroChatStationary.propTypes = {
  title: PropTypes.string,
  imageData: PropTypes.object,
};

export default HeroChatStationary;
