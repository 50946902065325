import React, { useEffect } from "react";
import SchemaOrg from "./schema-org";
import { useSiteInformation } from "../../hooks/useSiteInformation";
import { normalizeUrl } from "../../utils/normalizeUrl";

const Seo = props => {
  const {
    slug,
    metaImage,
    metaTitle,
    metaDescription,
    metaRobots,
    metaBots,
    locale,
    createdAt,
    updatedAt,
    children,
  } = props;

  const data = useSiteInformation();

  // Title variable
  const pageTitle = metaTitle
    ? `${metaTitle} | ${data.siteName}`
    : props.title
    ? `${props.title} | ${data.siteName}`
    : data.title;

  // MetaDescription variable, check if the value is passed to the SEO function
  // If there is no value passed, use the default from gatsby-config.js
  const pageDescription = metaDescription || data.description;

  // Meta robots and  Meta bots variables, join the values to comma separated strings,
  // check if the values is null. If there is null value passed, use the default values from gatsby-config.js
  const robots = metaRobots ? metaRobots.join(", ") : data.robots.join(", ");
  const bots = metaBots ? metaBots.join(", ") : data.bots.join(", ");

  const image = metaImage ? metaImage : props.image?.url ? props.image : null;

  // Meta Image
  const mImage = image
    ? `${image.url}`
    : `${data.siteUrl}/${data.metaimagePath}`;

  const mImageSize = {
    height: image ? image.height : data.metaimageHeight,
    width: image ? image.width : data.metaimageWidth,
  };

  // Page URL
  const pageUrl = normalizeUrl(`${data.siteUrl}/${slug}`);

  // Last Modified or Created at Timestamp
  const modifiedTime = updatedAt ? updatedAt : createdAt;

  // Update the browser tab title. For some reason it doesn't always update?
  useEffect(() => {
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [pageTitle]);

  return (
    <>
      <title>{pageTitle}</title>
      <link rel="canonical" href={pageUrl} />
      <link
        rel="icon"
        type="image/x-icon"
        href="https://d1hud7do04ixcl.cloudfront.net/on-favicon.ico"
      />
      <meta name="description" content={pageDescription} />
      <meta name="robots" content={robots} />
      <meta name="googlebot" content={bots} />
      <meta name="bingbot" content={bots} />

      {/** Twitter */}
      <meta name="twitter:creator" content={data.author} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />

      {/** Open Graph */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale.replace("-", "_")} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:site_name" content={data.siteName} />
      <meta property="og:updated_time" content={modifiedTime} />

      {mImage ? (
        <>
          <meta property="og:image" content={mImage} />
          <meta property="og:image:width" content={mImageSize.width} />
          <meta property="og:image:height" content={mImageSize.height} />
          <meta property="twitter:image" content={mImage} />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      ) : (
        <meta name="twitter:card" content="summary" />
      )}

      {children}

      <SchemaOrg
        canonicalUrl={pageUrl}
        siteUrl={data.siteUrl}
        siteDescription={data.description}
        siteLogo={`${data.siteUrl}/${data.metaimagePath}`}
        siteTitle={data.siteName}
        companyName={data.title}
        currentLanguage={"en"}
        currentLocale={locale.replace("-", "_")}
        metaImage={mImage}
        metaImageSize={mImageSize}
        {...props}
      />
    </>
  );
};

export default Seo;
