import PropTypes from "prop-types";
import React from "react";

import "./last-words.scss";

const LastWords = ({ children }) => (
  <h2 className="last-words heading-3">
    <div className="last-words__text">{children}</div>
  </h2>
);

LastWords.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LastWords;
