import React from "react";
import {
  baseSchema,
  blogPostSchema,
  articleSchema,
  newsArticleSchema,
} from "./schema-partials";

const SchemaOrg = props => {
  // Schema type map
  const schemaTypes = {
    Base: baseSchema(props),
    Blog: blogPostSchema(props),
    Article: articleSchema(props),
    NewsArticle: newsArticleSchema(props),
  };

  // Figure out if the page, based on the slug
  // needs a specific schema added to it, if it's not a blog post.
  // Blog Posts have their own schema.
  const pageSchema = slug => {
    if (slug && slug.startsWith("/resources/") && props.type !== "Blog") {
      return schemaTypes["Article"];
    }

    return null;
  };

  const schemaType =
    props.type && schemaTypes[props.type]
      ? schemaTypes[props.type]
      : pageSchema(props.slug);

  const schema = [
    {
      "@context": "http://schema.org",
      "@graph": [...schemaTypes["Base"], ...(schemaType ? schemaType : [])],
    },
  ];

  return <script type="application/ld+json">{JSON.stringify(schema)}</script>;
};

export default SchemaOrg;
