import Link from "../link/Link";
import PropTypes from "prop-types";
import React from "react";

import CallToAction from "../call-to-action/call-to-action";

import "./collage-small.scss";

const CollageSmall = ({ heading, description, path, pic, linkText }) => {
  let link;
  if (linkText) {
    link = (
      <div className="collage-small__text__cta">
        <Link className="careers-link" to={path}>
          <CallToAction text={linkText} />
        </Link>
      </div>
    );
  } else {
    link = "";
  }
  return (
    <section className="collage-small">
      <div className="collage-small__pic-wrap">
        <img className="collage-small__pic" src={pic} alt="" />
      </div>
      <section className="collage-small__text">
        <h2 className="collage-small__text__heading heading-4">{heading}</h2>
        <p className="collage-small__text__body body-text">{description}</p>
        {link}
      </section>
    </section>
  );
};

CollageSmall.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  path: PropTypes.string,
  pic: PropTypes.string.isRequired,
};

export default CollageSmall;
