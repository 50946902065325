import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@reach/router";

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  className,
  newWindow,
  style,
  onClick,
}) => {
  // Get current Location Object from the router
  const location = useLocation();
  // If there's no "to" then return a span with the child text.
  if (!to) {
    return (
      <span className={className} style={style} onClick={onClick}>
        {children}
      </span>
    );
  }

  // Open in a new window for any link that goes off the GameOn domains
  const external = RegExp(
    "^((f|ht)tps?:)?//(?!" + location.host + "|www.gameontechnology.com)"
  ).test(to);
  if (external) {
    newWindow = true;
  }

  // If the destination is not external (starts w/ http), a mail link (starts w/ mailto:)
  if (!to.startsWith("http") && !to.startsWith("mailto:") && !external) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        className={className}
        style={style}
        onClick={onClick}
      >
        {children}
      </GatsbyLink>
    );
  }

  // Get attributes ready to spread if link should open in a new window.
  const newWindowAttributes = newWindow
    ? { rel: "noopener noreferrer", target: "_blank" }
    : {};

  return (
    <a
      href={to}
      className={className}
      style={style}
      onClick={onClick}
      {...newWindowAttributes}
    >
      {children}
    </a>
  );
};

export default Link;
