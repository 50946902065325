// Basic WebPage Schema. It adds basic webpage information
// and information about the company (oragnization)
export const baseSchema = props => {
  const {
    siteUrl,
    canonicalUrl,
    siteTitle,
    siteDescription,
    description,
    title,
    currentLanguage,
    modifiedTime,
    createdTime,
  } = props;
  return [
    ...organizationSchema(props),
    {
      "@type": "WebSite",
      "@id": siteUrl + "#website",
      url: siteUrl,
      name: siteTitle,
      description: siteDescription,
      inLanguage: currentLanguage,
      publisher: {
        "@id": siteUrl + "/#organization",
      },
    },
    {
      "@type": "WebPage",
      "@id": canonicalUrl + "#webpage",
      url: canonicalUrl,
      name: title,
      isPartOf: {
        "@id": siteUrl + "#website",
      },
      datePublished: createdTime,
      dateModified: modifiedTime,
      description: description,
      inLanguage: currentLanguage,
      potentialAction: [
        {
          "@type": "ReadAction",
          target: [canonicalUrl],
        },
      ],
    },
  ];
};

// Schema for Blog Posts
export const blogPostSchema = props => {
  const {
    siteUrl,
    canonicalUrl,
    description,
    title,
    siteTitle,
    currentLanguage,
    modifiedTime,
    createdTime,
    metaImage,
    metaImageSize,
    siteLogo,
    companyName,
  } = props;
  return [
    {
      "@type": "BlogPosting",
      url: canonicalUrl,
      name: title,
      headline: title && title.replace(`| ${siteTitle}`, "").trim(), // Clean if the site information was included in the title,
      image: {
        "@type": "ImageObject",
        url: metaImage,
        height: metaImageSize.height,
        width: metaImageSize.width,
      },
      description,
      author: {
        "@type": "Organization",
        url: siteUrl,
        name: companyName,
      },
      publisher: {
        "@type": "Organization",
        url: siteUrl,
        logo: {
          "@type": "ImageObject",
          url: siteLogo,
          width: "819",
          height: "819",
        },
        name: companyName,
      },
      isPartOf: {
        "@id": canonicalUrl + "#webpage",
      },
      mainEntityOfPage: canonicalUrl + "#webpage",
      dateCreated: createdTime,
      datePublished: createdTime,
      dateModified: modifiedTime,
      inLanguage: currentLanguage,
    },
  ];
};

// Organization Schema. This is part of the web page schema above.
const organizationSchema = props => {
  const { siteUrl, siteLogo, companyName } = props;
  return [
    {
      "@type": "Organization",
      url: siteUrl,
      logo: {
        "@type": "ImageObject",
        url: siteLogo,
        width: "819",
        height: "819",
      },
      name: companyName,
    },
  ];
};

// Article schema. Currently added the "local" resources if it;s not a blog post
export const articleSchema = props => {
  const {
    canonicalUrl,
    siteUrl,
    createdTime,
    modifiedTime,
    currentLanguage,
    siteTitle,
    title,
    description,
    metaImage,
    metaImageSize,
    siteLogo,
    companyName,
  } = props;
  return [
    {
      "@type": "Article",
      "@id": canonicalUrl + "#article",
      url: canonicalUrl,
      name: title,
      headline: title && title.replace(`| ${siteTitle}`, "").trim(), // Clean if the site information was included in the title,
      image: {
        "@type": "ImageObject",
        url: metaImage,
        height: metaImageSize.height,
        width: metaImageSize.width,
      },
      description,
      isPartOf: {
        "@id": canonicalUrl + "#webpage",
      },
      author: {
        "@type": "Organization",
        url: siteUrl,
        name: companyName,
      },
      publisher: {
        "@type": "Organization",
        url: siteUrl,
        logo: {
          "@type": "ImageObject",
          url: siteLogo,
          width: "512",
          height: "512",
        },
        name: companyName,
      },
      dateCreated: createdTime,
      datePublished: createdTime,
      dateModified: modifiedTime,
      inLanguage: currentLanguage,
      mainEntityOfPage: canonicalUrl + "#webpage",
    },
  ];
};

// NewsArticle schema. Currently not used but might be helpful in the future
export const newsArticleSchema = props => {
  const {
    canonicalUrl,
    siteUrl,
    createdTime,
    modifiedTime,
    currentLanguage,
    title,
    siteTitle,
    description,
    metaImage,
    metaImageSize,
    siteLogo,
    companyName,
  } = props;
  return [
    {
      "@type": "NewsArticle",
      url: canonicalUrl,
      name: title,
      headline: title && title.replace(`| ${siteTitle}`, "").trim(), // Clean if the site information was included in the title
      image: {
        "@type": "ImageObject",
        url: metaImage,
        height: metaImageSize.height,
        width: metaImageSize.width,
      },
      description,
      isPartOf: {
        "@id": canonicalUrl + "#webpage",
      },
      author: {
        "@type": "Organization",
        url: siteUrl,
        name: companyName,
      },
      publisher: {
        "@type": "Organization",
        url: siteUrl,
        logo: {
          "@type": "ImageObject",
          url: siteLogo,
          width: "512",
          height: "512",
        },
        name: companyName,
      },
      dateCreated: createdTime,
      datePublished: createdTime,
      dateModified: modifiedTime,
      inLanguage: currentLanguage,
      mainEntityOfPage: canonicalUrl + "#webpage",
    },
  ];
};

// Product schema. Currently not used but might be helpful in the future
export const productSchema = props => {
  const { title, description, metaImage, metaImageSize } = props;
  return [
    {
      "@type": "Product",
      name: title,
      image: {
        "@type": "ImageObject",
        url: metaImage,
        height: metaImageSize.height,
        width: metaImageSize.width,
      },
      description: description,
      brand: {
        "@type": "Brand",
        name: "GameOn Technology",
      },
    },
  ];
};
