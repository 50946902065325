import PropTypes from "prop-types";
import React from "react";

import "./decor.scss";

const Decor = ({ type }) => <div className={`decor decor--${type}`} />;

Decor.propTypes = {
  type: PropTypes.string,
};

Decor.defaultProps = {
  type: "",
};

export default Decor;
