import React from "react";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import CallToAction from "../../../components/call-to-action/call-to-action";
import CollageSmall from "../../../components/collage-small/collage-small";
import Decor from "../../../components/decor/decor";
import HeroChatStationary from "../../../components/hero-chat-stationary/hero-chat-stationary";
import LastWords from "../../../components/last-words/last-words";
import LayoutSlack from "../../../components/layout-slack/layout-slack";
import Seo from "../../../components/seo/seo";

import "./arsenal.scss";

export const query = graphql`
  query ArsenalSlackQuery {
    headerImage: file(name: { eq: "arsenal-slack-001" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
  }
`;

const ArsenalSlack = ({ data }) => {
  return (
    <div className="slack-page">
      <LayoutSlack>
        <HeroChatStationary
          title="Arsenal"
          imageData={getImage(data.headerImage)}
        />

        <section className="under-chat">
          <h2 className="heading-3">The official Slack app of Arsenal</h2>
          <p className="heading-5">
            Gain unparalleled access to the Arsenal club, all delivered through
            Robot Pires! Ask for news, videos, fixtures, results, stats and
            more, including classic goals from one of the greatest ever Gunners.
            You can also follow real-time match updates, delivered to you as
            they happen.
          </p>
          <a
            className="outline-button"
            href="https://slack.com/oauth/v2/authorize?client_id=2251523783.398310569172&scope=app_mentions:read,channels:read,chat:write,groups:read,im:history&user_scope="
          >
            <CallToAction text="Add to Slack" />
          </a>
        </section>

        <Decor type="medium" />

        <h2 className="heading-2 collages-heading">Features</h2>

        <CollageSmall
          heading="News & Videos"
          description="Let Robot Pires deliver to you all the latest Arsenal News and Videos from the around the club."
          pic="/images/collage-small/arsenal-slack-feature-001.jpg"
        />

        <CollageSmall
          heading="Fixtures & Results"
          description="Stay connected with Arsenal on Slack and get all the latest results and updated fixtures sent to you by Robot Pires!"
          pic="/images/collage-small/arsenal-slack-feature-002.jpg"
        />

        <CollageSmall
          heading="Real-time match updates"
          description="The latest match updates are delivered in real-time to your Slack so you never miss a moment of any match!"
          pic="/images/collage-small/arsenal-slack-feature-003.jpg"
        />

        <div>
          <Decor type="long" />
        </div>

        <LastWords>
          Questions? Need help?
          <br className="desktop-line-break" />
          <Link to="/contact" className="underline--large-link">
            Contact us
          </Link>
        </LastWords>
      </LayoutSlack>
    </div>
  );
};

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "Arsenal App for Slack by GameOn",
    locale: "en-US",
    slug: location.pathname,
  };
  return <Seo {...seoMeta} />;
};

export default ArsenalSlack;
