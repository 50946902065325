import PropTypes from "prop-types";
import React from "react";

import ArrowRight from "../../../static/svg/arrow-right.svg";

import "./call-to-action.scss";

const CallToAction = ({ text }) => (
  <div className="cta">
    <div className="cta__text">{text}</div> <ArrowRight />
    <div className="cta__line" />
  </div>
);

CallToAction.propTypes = {
  text: PropTypes.string.isRequired,
};
export default CallToAction;
