/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types";
import React from "react";

import FooterSlack from "../footer-slack/footer-slack";

import "./layout-slack.scss";

const LayoutSlack = ({ children }) => (
  <div className="layout layout--slack" id="layout">
    <main>{children}</main>
    <FooterSlack />
  </div>
);

LayoutSlack.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutSlack;
